import axios from '@/libs/api.request'

// =============== news-system/articles/list.vue =========================
export const add = (formData) => {
    return axios.request({ url: '/api/admin/users', data: formData, method: 'post' })
}
export const getUserList = (formData, toPage, perPage) => {
  return axios.request({ url: '/api/users',
    params: {
      page: toPage,
      per_page: perPage,
      search_data: JSON.stringify(formData)
    },
    method: 'get' })
}
export const getDonationUserList = (id, name) => {
  return axios.request({ url: '/api/donationusers',
    params: {
      donation_id: id,
      name: name
    },
    method: 'get' })
}

export const getSmsCode = (formData) => {
    return axios.request({ url: '/api/send_sms', data: formData, method: 'post' })
}

export const addressInput = (formData) => {
    return axios.request({ url: '/api/useraddressinput', data: formData, method: 'post' })
}

export const userDonation = (formData) => {
  return axios.request({ url: '/api/userdonation', data: formData, method: 'post' })
}
export const wxPayParams = (formData) => {
  return axios.request({ url: '/api/wx/pay',
      data: formData,
      method: 'post'
  })
}
export const otherUserDonation = (formData) => {
  return axios.request({ url: '/api/otheruserdonation', data: formData, method: 'post' })
}

export const login = (formData) => {
    return axios.request({ url: '/api/applogin', data: formData, method: 'post' })
}
export const checkUser = (formData) => {
  return axios.request({ url: '/api/checkuser', data: formData, method: 'post' })
}

export const checkSms = ({ phone, captcha }) => {
    const data = {
        sms_key: phone,
        sms_code: captcha
    }
    return axios.request({ url: '/api/check_sms_code', data: data, method: 'post' })
}

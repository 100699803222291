import axios from '@/libs/api.request'

// =============== news-system/articles/list.vue =========================
export const getProjectsData = (topage, perpage, searchData) => {
    return axios.request({
        url: '/api/admin/donationprojects/list',
        params: {
            page: topage,
            per_page: perpage,
            search_data: JSON.stringify(searchData)
        },
        method: 'get'
    })
}
export const getUsersProjectsData = (topage, perpage, searchData) => {
  return axios.request({
    url: '/api/admin/donationprojects/ulist',
    params: {
      page: topage,
      per_page: perpage,
      search_data: JSON.stringify(searchData)
    },
    method: 'get'
  })
}
export const getCategories = () => {
    return axios.request({ url: '/api/admin/categories/all', method: 'get' })
}

<template>
  <div class="headerdiv">
<!--    <headerderail :headertitle="headertitle" :backshow="backshow"></headerderail>-->
    <div class="headerdetai">
      <div class="header_left">
        <div class="backdiv oneitem_center" @click="backclick" v-show="backshow"><i class="cubeic-back"></i></div>
      </div>
      <div class="searchInput" style="padding: 0px 5px;">
        <cube-input v-model="searchForm.name"></cube-input>
        <cube-button icon="cubeic-search" @click="searchClick"></cube-button>
      </div>
    </div>
    <div class="dltopdiv">
      <img src="../../assets/aixin.jpg" alt="" width="100%">
    </div>
    <div class="dl-contentdiv" style="overflow-y: auto" :style="{position: 'absolute', top: topHeight+'px', bottom: '50px',left:'0', right: '0'}">
      <cube-index-list v-if="feeds.data.length > 0" :data="feeds.data"
                       ref="indexList"
                       :navbar="false"
                       :title="''"
                       @pulling-up="onPullingUp"
                       :pullUpLoad="feeds.last_page > feeds.current_page"
      >
        <cube-index-list-item
          v-for="(user, index) in feeds.data"
          :key="index"
          :item="user"
          :navbar="false"
          @select="selectItem"
        >
          <div class="listitem">
            <div class="listitem-left">
              <img v-if="user.head_image.attachment_id !== 0" :src="user.head_image.url" alt="" style="width: 60%">
              <img v-if="user.head_image.attachment_id === 0" src="../../assets/userimages.png" alt="" style="width: 60%">
            </div>
            <div class="listitem-center">
              <h1 style="font-size: 1.3rem;font-weight: 500;margin-bottom: 0;">{{user.name}} <span style="font-size: .8rem">{{user.village}}</span></h1>
              <p style="font-size: .7rem;font-weight: 500;">
                今年捐款 <span style="font-size: 1rem;color: red;font-weight: 600;"> {{user.yearmount}}</span> 元
              </p>
              <p style="font-size: .6rem;">入会时间:{{user.comein_at.slice(0,10)}}</p>
            </div>
            <div class="listitem-right" style="text-align: center;">
              <h1 style="margin-right: 10px;color: red;font-size: 1.5rem;"> ￥{{user.jkmount}}</h1>
              <h5 style="font-size: .8rem;font-weight: 400;">总捐款</h5>
            </div>
          </div>
        </cube-index-list-item>
      </cube-index-list>
      <ul v-if="feeds.data.length === 0" style="width: 100%;text-align: center">
        <img src="../../assets/nocartdata.png" alt="" width="100" style="margin: auto; display: block;margin-top: 20px;">
        <h2 style="margin-top: 10px;">没有数据</h2>
      </ul>
    </div>
<!--    <div class="dl-contentdiv" >-->
<!--      <cube-tab-bar v-model="selectedLabelSlots" show-slider @click="clickHandler" style="background:white;">-->
<!--        <cube-tab icon="cubeic-home" label="慈善榜" key=1>-->
<!--        </cube-tab>-->
<!--        <cube-tab icon="cubeic-like" label="捐款动态" key=2>-->
<!--        </cube-tab>-->
<!--      </cube-tab-bar>-->
<!--      <cube-tab-panels v-model="selectedLabelSlots" style="overflow-y: auto" :style="{position: 'absolute', top: topHeight+'px', bottom: '50px',left:'0', right: '0'}">-->
<!--        <cube-tab-panel label="慈善榜" key=1 style="margin-top: 5px">-->
<!--          <cube-index-list v-if="feeds.data.length > 0" :data="feeds.data"-->
<!--                           ref="indexList"-->
<!--                           :navbar="false"-->
<!--                           :title="''"-->
<!--                           @pulling-up="onPullingUp"-->
<!--                           :pullUpLoad="true"-->
<!--          >-->
<!--              <cube-index-list-item-->
<!--                v-for="(user, index) in feeds.data"-->
<!--                :key="index"-->
<!--                :item="user"-->
<!--                @select="selectItem"-->
<!--              >-->
<!--                <div class="listitem">-->
<!--                  <div class="listitem-left">-->
<!--                    <img v-if="user.head_image.attachment_id !== 0" :src="user.head_image.url" alt="" style="width: 60%">-->
<!--                    <img v-if="user.head_image.attachment_id === 0" src="../../assets/userimages.png" alt="" style="width: 60%">-->
<!--                  </div>-->
<!--                  <div class="listitem-center">-->
<!--                    <h1 style="font-size: 1.3rem;font-weight: 500;margin-bottom: 0;">{{user.name}} <span style="font-size: .8rem">{{user.village}}</span></h1>-->
<!--                    <p style="font-size: .7rem;font-weight: 500;">-->
<!--                      今年捐款总金额 <span style="font-size: 1rem;color: red;font-weight: 600;"> {{user.yearmount}}</span> 元-->
<!--                    </p>-->
<!--                    <p style="font-size: .6rem;">{{user.comein_at}}</p>-->
<!--                  </div>-->
<!--                  <div class="listitem-right" style="text-align: center;">-->
<!--                    <h1 style="margin-right: 10px;color: red;font-size: 1.5rem;"> ￥{{user.jkmount}}</h1>-->
<!--                    <h5 style="font-size: .8rem;font-weight: 400;">总捐款</h5>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </cube-index-list-item>-->
<!--          </cube-index-list>-->
<!--          <ul v-if="feeds.data.length === 0" style="width: 100%;text-align: center">-->
<!--            <img src="../../assets/nocartdata.png" alt="" width="100" style="margin: auto; display: block;margin-top: 20px;">-->
<!--            <h2 style="margin-top: 10px;">没有数据</h2>-->
<!--          </ul>-->
<!--        </cube-tab-panel>-->
<!--        <cube-tab-panel label="捐款动态" key=2 style="margin-top: 5px">-->
<!--          <div class="searchInput">-->
<!--            <cube-input v-model="pSearchForm.title"></cube-input>-->
<!--            <cube-button icon="cubeic-search" @click="pSearchClick"></cube-button>-->
<!--          </div>-->
<!--          <ul v-if="pFeeds.data.length > 0">-->
<!--            <li class="tab-panel-li" v-for="(item, index) in pFeeds.data" :key="index" @click="donationItemClick(item)">-->
<!--              <div class="listitem">-->
<!--                <div class="listitem-left">-->
<!--                  <img :src="item.project_image.url" alt="" style="width: 60%">-->
<!--                </div>-->
<!--                <div class="listitem-center">-->
<!--                  <h1 style="font-size: 1.3rem;font-weight: 500;margin-bottom: 0;">{{item.title}} </h1>-->
<!--                  <p style="font-size: .7rem;font-weight: 500;">-->
<!--                    {{item.sub_title}}-->
<!--                  </p>-->
<!--                  <p style="font-size: .6rem;">{{item.start_at}}</p>-->
<!--                </div>-->
<!--                <div class="listitem-right" style="text-align: center;">-->
<!--                  <h1 style="margin-right: 10px;color: red;font-size: 1.5rem;"> ￥{{item.jkmount}}</h1>-->
<!--                  <h5 style="font-size: .8rem;font-weight: 400;">所需总捐款</h5>-->
<!--                </div>-->
<!--              </div>-->
<!--            </li>-->
<!--          </ul>-->
<!--          <ul v-if="pFeeds.data.length === 0" style="width: 100%;text-align: center">-->
<!--            <img src="../../assets/nocartdata.png" alt="" width="100" style="margin: auto; display: block;margin-top: 20px;">-->
<!--            <h2 style="margin-top: 10px;">没有数据</h2>-->
<!--          </ul>-->
<!--        </cube-tab-panel>-->
<!--      </cube-tab-panels>-->
<!--    </div>-->
    <div class="donation_buttom" v-show="donationButtomShowNot">
      <cube-button :primary="true" style="margin-left: 0%;height: 100%;" @click="donationClick(0)">立即捐款</cube-button>
<!--      <cube-button :primary="true" style="height: 100%;" @click="donationClick(1)">帮朋友捐款</cube-button>-->
    </div>
    <cube-popup type="my-popup" position="bottom" :mask-closable="true" ref="myPopup4">
      <div class="donation_self" v-show="donationSelfShow">
<!--        <cube-form :model="donation" @validate="validateHandler" @submit.prevent="selfdonationClick">-->
<!--          <cube-form-group>-->
<!--            <cube-form-item style="padding: 5px 0;" :field="fields[2]">-->
<!--            </cube-form-item>-->
<!--          </cube-form-group>-->
<!--          <cube-form-group>-->
<!--            <cube-button :primary="true" type="submit">立即捐款</cube-button>-->
<!--          </cube-form-group>-->
<!--        </cube-form>-->
        <cube-form :model="donation" @validate="validateHandler" @submit.prevent="selfdonationClick">
          <cube-form-group>
            <cube-form-item :field="fields[0]"></cube-form-item>
            <cube-form-item :field="fields[1]"></cube-form-item>
            <cube-form-item :field="fields[2]">
            </cube-form-item>
            <cube-form-item :field="fields[3]">
            </cube-form-item>
          </cube-form-group>
          <cube-form-group>
            <cube-button :primary="true" type="submit">立即捐款</cube-button>
          </cube-form-group>
        </cube-form>
      </div>
      <div class="donation_other" v-show="!donationSelfShow">
        <cube-form :model="donation" @validate="validateHandler" @submit.prevent="submitHandler">
          <cube-form-group>
            <cube-form-item style="padding: 5px 0;" :field="fields[0]"></cube-form-item>
            <cube-form-item style="padding: 5px 0;" :field="fields[1]"></cube-form-item>
            <cube-form-item style="padding: 5px 0;" :field="fields[2]">
            </cube-form-item>
            <cube-form-item :field="fields[3]">
            </cube-form-item>
          </cube-form-group>
          <cube-form-group>
            <cube-button :primary="true" type="submit">立即捐款</cube-button>
          </cube-form-group>
        </cube-form>
      </div>
    </cube-popup>
  </div>
</template>

<script>
    // import headerderail from 'cp/v-header/headerdetail.vue'
    import {
      getUserList,
      // userDonation,
      checkUser,
      wxPayParams,
      otherUserDonation
    } from 'api/user'
    import {
      getProjectsData
    } from 'api/donationprojects'
    import { todoStorage } from 'libs/storage'
    // import { getUrlKey } from 'libs/tools'
    export default {
      name: 'donation',
      components: {
      },
      data () {
        return {
          topHeight: 0,
          donation: {
            name: '',
            phone: '',
            value: '',
            village: ''
          },
          donationSelfShow: true,
          backshow: true,
          donationButtomShowNot: true,
          headertitle: '会员捐款',
          selectedLabelSlots: '慈善榜',
          tabs: [
            {
              label: '慈善榜',
              icon: 'cubeic-home'
            },
            {
              label: '捐款动态',
              icon: 'cubeic-like'
            }
          ],
          searchForm: {
            order_by: 'jkmount,desc',
            name: ''
          },
          pSearchForm: {
            order_by: 'id,desc',
            title: '',
            enable: 'T'
          },
          feeds: {
            data: [],
            total: 0,
            last_page: 0,
            current_page: 1,
            per_page: 10
          },
          pFeeds: {
            data: [],
            total: 0,
            current_page: 1,
            per_page: 10
          },
          fields: [
            {
              type: 'input',
              modelKey: 'name',
              label: '姓名：',
              props: {
                placeholder: '请输入您朋友的姓名',
                type: 'text'
              },
              rules: {
                required: true
              }
            },
            {
              type: 'input',
              modelKey: 'phone',
              label: '电话：',
              props: {
                placeholder: '请输入您朋友的电话',
                type: 'number'
              },
              rules: {
                required: true
              }
            },
            {
              type: 'input',
              modelKey: 'value',
              label: '金额：',
              props: {
                placeholder: '请输入您要捐款的金额',
                type: 'number'
              },
              rules: {
                required: true
              }
            },
            {
              type: 'input',
              modelKey: 'village',
              label: '村名：',
              props: {
                placeholder: '新会员，一定要输入村名',
                type: 'text'
              }
            }
          ]
        }
      },
      mounted () {
        this.getDataExcute(this.feeds.current_page)
        // this.getPDataExcute(this.pFeeds.current_page)
        this.topHeight = document.body.clientWidth / 2 + 60
        // console.log(document.body.clientWidth)
      },
      methods: {
        backclick () {
          if (window.history.length <= 2) {
            this.$router.push({ path: '/' })
            return false
          } else {
            this.$router.go(-1)
          }
        },
        selectItem () {
        },
        onPullingUp () {
          let t = this
          if (t.feeds.last_page > t.feeds.current_page) {
            t.feeds.current_page = t.feeds.current_page + 1
            t.getDataExcute(t.feeds.current_page)
          } else {
            t.NoteTips('没有更多数据')
          }
          t.$refs.indexList.forceUpdate()
        },
        searchClick () {
          let t = this
          t.feeds.data = []
          t.getDataExcute(1)
        },
        selfdonationClick () {
          let t = this
          t.showLoading()
          checkUser(t.donation).then(res => {
              console.log(res)
             todoStorage.setUserData = res.data
             todoStorage.setUserIsLogin = true
              var data = {
                id: res.data.id,
                jkmount: t.donation.value,
                donation_id: 0,
                openid: todoStorage.getWxOpenid
              }
              wxPayParams(data).then(res => {
                window.WeixinJSBridge.invoke(
                  'getBrandWCPayRequest',
                  JSON.parse(res.data),
                  function (res) {
                    t.NoteTips('支付成功')
                    location.reload()
                  }
                )
                t.hideLoading()
              }, function (error) {
                t.NoteTips('支付失败')
                t.hideLoading()
                location.reload()
                console.log(error)
              })
          }, function (error) {
            console.log(error)
            t.hideLoading()
          })
        },
        submitHandler (e) {
          let userdata = todoStorage.getUserData
          let t = this
          if (todoStorage.getUserIsLogin) {
            t.showLoading()
            var data = {
              id: userdata.id,
              name: t.donation.name,
              phone: t.donation.phone,
              jkmount: t.donation.value
            }
            otherUserDonation(data).then(res => {
              console.log(res)
              t.hideLoading()
            }, function (error) {
              console.log(error)
              t.hideLoading()
            })
          } else {
            t.$router.push({ name: 'login' })
          }
        },
        validateHandler (result) {
          // this.validity = result.validity
          // this.valid = result.valid
          // console.log('validity', result.validity, result.valid, result.dirty, result.firstInvalidFieldIndex)
        },
        donationClick (index) {
          let t = this
          const component = t.$refs.myPopup4
          component.show()
          if (index === 0) {
            t.donationSelfShow = true
          } else {
            t.donationSelfShow = false
          }
        },
        donationItemClick (item) {
          var t = this
          todoStorage.setSelectProject = item
          t.$router.push({ name: 'dulist', params: { donationid: item.id } })
        },
        clickHandler (label) {
          // if you clicked home tab, then print 'Home'
          console.log(label)
          switch (label) {
            case '慈善榜':
              this.donationButtomShowNot = true
              break
            case '捐款动态':
              this.donationButtomShowNot = false
              break
          }
        },
        getDataExcute (topage) {
          let t = this
          t.showLoading()
          t.feeds.current_page = topage
          var userdata = todoStorage.getUserList
          if (!userdata) {
            getUserList(t.searchForm, t.feeds.current_page, t.feeds.per_page).then(res => {
              console.log(res)
              t.feeds.last_page = res.meta.last_page
              t.feeds.data.push.apply(t.feeds.data, res.data)
              // t.feeds.data.concat(res.data)
              t.feeds.total = res.meta.total
              t.hideLoading()
            }, function (error) {
              t.hideLoading()
              console.log(error)
            })
          } else {
            t.feeds.current_page = topage
          }
        },
        getPDataExcute (topage) {
          let t = this
          t.showLoading()
          getProjectsData(topage, t.pFeeds.per_page, t.pSearchForm).then(res => {
            console.log(res)
            t.pFeeds.data = res.data
            // console.log(res)
            t.pFeeds.total = res.meta.total
            t.hideLoading()
          }, function (error) {
            t.hideLoading()
            console.log(error)
          })
        }
      }
    }
</script>

<style lang='stylus' rel='stylesheet/stylus'>
  @import 'list.styl'
</style>
